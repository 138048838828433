import axios from "axios"
import { BranchesInterface, GetBranchesInterface } from "@type/branches.types"

const ROOT_PATH = "/branches"

export default class BranchesService {
	static getBranches = (fetchAll: boolean) => axios.get<GetBranchesInterface[]>(ROOT_PATH, { params: { fetchAll } })

	static createBranch = (branch: BranchesInterface, organization: string) =>
		axios.post<BranchesInterface>(ROOT_PATH, { branch, organization })

	static updateBranch = (id: string, branchData: BranchesInterface) =>
		axios.put<BranchesInterface>(`${ROOT_PATH}/${id}`, branchData)
}
